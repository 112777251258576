import { isDev, getEnv, envInfo } from '$lib/utils/env';

const SENTRY_DSN = getEnv('SENTRY_DSN') as string;
const environment = envInfo.name;
const release = 'r' + envInfo.app.versionTag;

const sentryOptions = {
	dsn: SENTRY_DSN,
	tracesSampleRate: 1,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: isDev() ? 1 : 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	environment,
	release
};

export { sentryOptions };
