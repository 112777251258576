import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { sentryOptions } from './lib/sentry';

Sentry.init({
	...sentryOptions,
	integrations: [replayIntegration()]
});

export const handleError = handleErrorWithSentry();
